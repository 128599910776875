import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { OpacityBox } from 'src/components/UI/OpacityBox';
import { UIButton } from 'src/components/UI/UIButton';
import { UIInput } from 'src/components/UI/UIInput';
import { FCC } from 'src/types/FCC';

const currentCode = localStorage.getItem('currentCode') || null;
const trueCode = import.meta.env.VITE_MIGRATION_CODE;

export const MigrationCodeGuard: FCC<{ isMigration?: boolean }> = ({ children, isMigration }) => {
  const [code, setCode] = useState('');
  const [userInput, setUserInput] = useState('');

  if (code === trueCode || currentCode === trueCode) return children || <Outlet />;

  return (
    <div className="container mx-auto flex-grow gap-4 flex flex-col">
      <OpacityBox>
        <h1 className="text-lg">
          The {isMigration ? 'public migration stage' : 'staking'} has been closed.
          <br />
          If you experienced problem and got an access code from{' '}
          <a
            className="font-semibold cursor-pointer underline inline text-[#FB1FFF]"
            href="https://t.me/aigentx_po"
            target="_blank"
          >
            @aigentx_po
          </a>{' '}
          enter it below to access the migration
        </h1>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setCode(userInput);
            localStorage.setItem('currentCode', userInput);
          }}
          className="mt-8 flex items-center gap-4"
        >
          <UIInput
            error={!!code && code !== trueCode}
            errorMessage="Wrong code"
            containerClasses="flex-grow"
            label="Access code"
            placeholder="Access code"
            name="code"
            value={userInput}
            onChange={(e) => {
              setCode('');
              setUserInput(e.target.value);
              localStorage.setItem('currentCode', userInput);
            }}
          />
          <UIButton onClick={() => setCode(userInput)}>Submit</UIButton>
        </form>
      </OpacityBox>
    </div>
  );
};
